﻿import TKCustomElementFactory from '@tk/utilities/tk.custom.element.factory';
import render from '@tk/utilities/tk.render';
import {
    SortDirection,
    SortDirectionEnum,
    compareBooleans,
    compareDates,
    compareNumbers,
    compareStrings,
    compareTimes,
    compareTimestamps,
} from '@tk/utilities/tk.sort';
import { fetchRequest } from '@tk/utilities/tk.fetch';

enum DataMaster {
    CLIENT = 'client',
}

type DataType = 'A' | 'N' | 'D' | 'H' | 'S' | 'B';

export interface TableHeadItem {
    element: HTMLTableCellElement;
    type: DataType;
    sortDirection: SortDirection;
    column: number;
}

export interface TableRow {
    element: HTMLTableRowElement;
    value: string;
}

export default class TKTable extends TKCustomElementFactory {
    headElement?: HTMLElement;
    rowHeadElement?: HTMLElement;
    headShadowClassName: string;
    isStickyLeft: boolean;
    isStickyHead: boolean;
    colAlignStart: boolean;
    colAlignStartClassName: string;
    colItemAlignCenter: boolean;
    colItemAlignCenterClassName: string;
    colItemAlignStart: boolean;
    colItemAlignStartClassName: string;
    colItemAlignEnd: boolean;
    colItemAlignEndClassName: string;
    gridTemplateMobile: string;
    loadedClassName: string;
    stickyLeftClassname: string;
    rightShadowClassName: string;
    stickyHeadClassName: string;
    customCols: string;
    observer: ResizeObserver;
    gridTemplateColList?: string[] = [];
    desktopMinWidth: number;
    isSortTable: boolean;
    ascSortClassName: string;
    descSortClassName: string;
    rowLinkSelector: string;

    protected table?: HTMLTableElement;
    protected boxShadowElement?: HTMLElement;
    protected horizontalScrollShadowElement?: HTMLElement;
    protected isHorizontalScrollable: boolean = false;

    protected tableSelector: string;
    protected sortDirectionSelector: string;
    protected tkSortDirectionSelector: string;
    protected tkSortAttrSelector: string;
    protected tkSortDatatypeSelector: string;
    protected dataMasterSelector: string;
    protected dataSortAttrSelector: string;

    constructor() {
        super();

        this.headElement = this.querySelector('[data-tk-table-head]') || undefined;
        if (!this.headElement) throw new Error('Table: Head is missing!');
        this.rowHeadElement = this.headElement.querySelector('[data-tk-table-row]') || undefined;
        this.headShadowClassName = this.getAttribute('data-tk-head-shadow-class-name') || 'tk-table__head-shadow';

        this.isStickyLeft = this.hasAttribute('data-tk-is-sticky-left');
        this.isStickyHead = this.hasAttribute('data-tk-is-sticky-head');
        this.gridTemplateMobile = this.getAttribute('data-tk-grid-template-mobile') || '1fr';

        this.loadedClassName = this.getAttribute('data-tk-loaded-class-name') || 'tk-table--loaded';
        this.stickyLeftClassname = this.getAttribute('data-tk-sticky-left-class-name') || 'tk-table--sticky-left';
        this.rightShadowClassName = this.getAttribute('data-tk-right-shadow-class-name') || 'tk-table__right-shadow';
        this.stickyHeadClassName = this.getAttribute('data-tk-sticky-head-class-name') || 'tk-table--sticky-head';

        this.customCols = this.getAttribute('data-tk-col-width') || 'auto';
        this.observer = new ResizeObserver(this.handleResize.bind(this));
        this.desktopMinWidth = Number(this.getAttribute('data-tk-desktop-min-width')) || 992;

        this.rowLinkSelector = 'data-tk-table-row-link';
        this.table = this.querySelector('table') || undefined;
        this.ascSortClassName = this.getAttribute('data-tk-asc-sort-class-name') || 'tk-table--sort-asc';
        this.descSortClassName = this.getAttribute('data-tk-desc-sort-class-name') || 'tk-table--sort-desc';
        this.isSortTable = Boolean(this.table?.hasAttribute('data-tk-table-sort'));

        this.colAlignStart = this.hasAttribute('data-tk-col-align-start');
        this.colAlignStartClassName = this.getAttribute('data-tk-col-align-start-class-name')
            || 'tk-table__col--align-left';

        this.colItemAlignCenter = this.hasAttribute('data-tk-col-item-align-center');
        this.colItemAlignCenterClassName = this.getAttribute('data-tk-col-item-align-center-class-name')
            || 'tk-table__col--item-align-center';
        this.colItemAlignStart = this.hasAttribute('data-tk-col-item-align-start');
        this.colItemAlignStartClassName = this.getAttribute('data-tk-col-item-align-start-class-name')
            || 'tk-table__col--item-align-start';
        this.colItemAlignEnd = this.hasAttribute('data-tk-col-item-align-end');
        this.colItemAlignEndClassName = this.getAttribute('data-tk-col-item-align-end-class-name')
            || 'tk-table__col--item-align-end';

        this.tableSelector = '[data-tk-table-sort]';
        this.sortDirectionSelector = '[data-tk-sort-direction]';
        this.tkSortDirectionSelector = 'tkSortDirection';
        this.tkSortAttrSelector = 'tkSortAttr';
        this.tkSortDatatypeSelector = 'tkSortDatatype';
        this.dataMasterSelector = 'data-tk-datamaster';
        this.dataSortAttrSelector = 'data-tk-sort-attr';
    }

    connectedCallback() {
        this.classList.add(this.loadedClassName);
        window.viewport.clientWidth < this.desktopMinWidth ? this.setGridMobile() : this.setGrid();
        this.registerViewportListener();
        this.registerScrollListener();
        this.registerRowLinkListener();
        this.observer.observe(this);
        this.isStickyHead && this.setStickyHead();
        this.isSortTable && this.registerTableHeadListener();
        this.colAlignStart && this.setColAlignStart();
        this.colItemAlignCenter && this.setColItemAlign(this.colItemAlignCenterClassName);
        this.colItemAlignStart && this.setColItemAlign(this.colItemAlignStartClassName);
        this.colItemAlignEnd && this.setColItemAlign(this.colItemAlignEndClassName);
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        this.observer.unobserve(this);
    }

    registerViewportListener(): void {
        const onViewportResize = this.handleViewportResize.bind(this);
        this.pushListener({
            event: 'tk-viewport-resize',
            element: window.viewport,
            action: onViewportResize,
        });
    }

    handleViewportResize(event: Event): void {
        const customEvent = event as CustomEvent;
        if (customEvent.detail.width < this.desktopMinWidth) {
            this.setGridMobile();
        } else {
            this.setGrid();
        }
    }

    registerScrollListener() {
        const handleHorizontalScrollListener = this.toggleScrollHorizontalBehavior.bind(this);
        this.pushListener({
            event: 'scroll',
            element: this,
            action: handleHorizontalScrollListener,
        });

        if (this.isStickyLeft) {
            const handleStickyLeftListener = this.toggleScrollLeftBehavior.bind(this);
            this.pushListener({
                event: 'scroll',
                element: this,
                action: handleStickyLeftListener,
            });
        }

        if (this.isStickyHead) {
            const handleStickyHeadListener = this.toggleScrollTopBehavior.bind(this);
            this.pushListener({
                event: 'scroll',
                element: this,
                action: handleStickyHeadListener,
            });
        }
    }

    registerTableHeadListener() {
        const handleSortTable = this.handleSortTable.bind(this);
        this.pushListener({
            event: 'click',
            element: this,
            action: handleSortTable,
        });
    }

    registerRowLinkListener() {
        const handleRowLink = this.handleRowLink.bind(this);
        this.pushListener({
            event: 'click',
            element: this,
            action: handleRowLink,
        });
    }

    handleRowLink(event: Event) {
        const target = event.target as HTMLElement;
        if (target.closest('a')) return;
        const row = target.closest(`[${this.rowLinkSelector}]`);
        if (!row) return;
        const link = row.getAttribute(this.rowLinkSelector);
        if (!link) return;
        window.location.href = link;
    }

    handleSortTable(event: Event) {
        if (!event) return;
        const target = event.target as HTMLElement;
        const column = target.closest('th');
        column && column.hasAttribute('data-tk-sort-direction') && this.sortTable(column);
    }

    handleResize(entries: ResizeObserverEntry[]) {
        entries.forEach(() => {
            this.isHorizontalScrollable = this.scrollWidth > this.clientWidth;
            if (this.isHorizontalScrollable && !this.horizontalScrollShadowElement) {
                this.setHorizontalScrollShadow();
            }
            if (!this.horizontalScrollShadowElement) return;
            this.horizontalScrollShadowElement.hidden = !this.isHorizontalScrollable;
            this.boxShadowElement && (this.boxShadowElement.style.width = `${this.scrollWidth}px`);
        });
    }

    setGrid() {
        if (!this.rowHeadElement) return;
        const cols = this.rowHeadElement.childElementCount;
        this.gridTemplateColList = this.customCols?.split(/(?!, ),/g);
        this.style.gridTemplateColumns = this.customCols?.includes(',')
            ? this.gridTemplateColList?.join(' ')
            : `repeat(${cols}, ${this.customCols})`;
        if (this.headElement) {
            this.headElement.classList.remove('tk-table__head--force-mobile');
        }

        const titleElements = this.table?.querySelectorAll('[data-tk-table-body] .tk-table__title');
        if (!titleElements) return;
        titleElements.forEach((titleElement) => {
            (titleElement as HTMLSpanElement).classList.remove('tk-table__title--force-mobile');
        });
    }

    setGridMobile() {
        this.style.gridTemplateColumns = this.gridTemplateMobile;
        if (this.headElement) {
            this.headElement.classList.add('tk-table__head--force-mobile');
        }

        const titleElements = this.table?.querySelectorAll('[data-tk-table-body] .tk-table__title');
        if (!titleElements) return;
        titleElements.forEach((titleElement) => {
            (titleElement as HTMLSpanElement).classList.add('tk-table__title--force-mobile');
        });
    }

    toggleScrollLeftBehavior(event: Event) {
        const element = event.target as HTMLElement;
        this.classList.toggle(this.stickyLeftClassname, element.scrollLeft > 0);
    }

    toggleScrollHorizontalBehavior(event: Event) {
        if (!this.horizontalScrollShadowElement) return;
        const { scrollLeft, scrollWidth, clientWidth } = event.target as HTMLElement;
        this.horizontalScrollShadowElement.hidden = scrollLeft >= scrollWidth - clientWidth;
        this.horizontalScrollShadowElement.style.right = `-${scrollLeft}px`;
    }

    toggleScrollTopBehavior(event: Event) {
        if (!this.boxShadowElement) return;
        const element = event.target as HTMLElement;
        this.boxShadowElement.hidden = element.scrollTop === 0;
        this.classList.toggle(this.stickyHeadClassName, element.scrollTop > 0);
        this.boxShadowElement.style.top = element.scrollTop.toFixed();
    }

    setStickyHead() {
        if (!this.headElement) return;
        this.boxShadowElement = render(`<div class='${this.headShadowClassName}' hidden></div>`);
        if (!this.boxShadowElement) return;
        const column = this.rowHeadElement?.firstElementChild;
        const height = column?.getBoundingClientRect().height || 0;
        this.boxShadowElement.style.width = `${this.scrollWidth}px`;
        this.boxShadowElement.style.height = `${height}px`;
        this.headElement.insertAdjacentElement('afterbegin', this.boxShadowElement);
    }

    setHorizontalScrollShadow() {
        this.horizontalScrollShadowElement = render(`<div class='${this.rightShadowClassName}'></div>`);
        if (!this.horizontalScrollShadowElement) return;
        this.insertAdjacentElement('beforeend', this.horizontalScrollShadowElement);
    }

    sortTable(column: HTMLTableCellElement) {
        const dataMaster = this.table?.getAttribute(this.dataMasterSelector);
        if (dataMaster === DataMaster.CLIENT) {
            this.setOrToggleSortDirection(column);
            this.clearHeadElementClassList(column);
            this.clientSortTable({
                element: column,
                type: column.dataset[this.tkSortDatatypeSelector] as DataType,
                sortDirection: column.dataset[this.tkSortDirectionSelector] as SortDirection,
                column: column.cellIndex,
            });
            this.postAsynClientSortState(column);
        } else {
            this.serverAsynTableSortRequest(column);
        }
    }

    clientSortTable(headItem: TableHeadItem) {
        const tableBody = this.table?.querySelector('[data-tk-table-body]');
        if (!tableBody) return;
        const rows = tableBody.querySelectorAll('[data-tk-table-row]');
        if (!rows) return;

        const rowList = Array.from(rows).map((element) => {
            const value = (
                Array.from(element.querySelectorAll('[data-tk-table-value]')).at(headItem.column)?.textContent || ''
            );
            return { element, value } as TableRow;
        });

        const sortTypes: Record<DataType, (a: string, b: string) => number> = {
            A: (a: string, b: string) => compareStrings(a, b, headItem.sortDirection),
            N: (a: string, b: string) => compareNumbers(a, b, headItem.sortDirection),
            D: (a: string, b: string) => compareDates(a, b, headItem.sortDirection),
            H: (a: string, b: string) => compareTimes(a, b, headItem.sortDirection),
            S: (a: string, b: string) => compareTimestamps(a, b, headItem.sortDirection),
            B: (a: string, b: string) => compareBooleans(a, b, headItem.sortDirection),
        };

        const sortedList = rowList.sort((a, b) => sortTypes[headItem.type](a.value, b.value));
        tableBody.innerHTML = '';
        sortedList.forEach((item) => {
            tableBody.appendChild(item.element);
        });

        if (
            headItem.element.classList.contains(this.ascSortClassName)
            || headItem.element.classList.contains(this.descSortClassName)
        ) {
            headItem.sortDirection.toUpperCase() === SortDirectionEnum.DESC
                ? headItem.element.classList.replace(this.ascSortClassName, this.descSortClassName)
                : headItem.element.classList.replace(this.descSortClassName, this.ascSortClassName);
        } else {
            headItem.sortDirection.toUpperCase() === SortDirectionEnum.DESC
                ? headItem.element.classList.add(this.descSortClassName)
                : headItem.element.classList.add(this.ascSortClassName);
        }
    }

    clearHeadElementClassList(column: HTMLTableCellElement) {
        if (!this.rowHeadElement) return;
        const head = Array.from(this.rowHeadElement.children);
        const filteredHead = head.filter((item) => item !== column);
        filteredHead.forEach((child) => {
            const element = child as HTMLElement;
            element.classList.remove(this.ascSortClassName);
            element.classList.remove(this.descSortClassName);
        });
    }

    setOrToggleSortDirection(thElement: HTMLTableCellElement) {
        this.removeUnseletectedSortDir(thElement);
        // toggelt direction auf geklickten header spalte
        thElement.dataset[this.tkSortDirectionSelector] = (
            thElement.dataset[this.tkSortDirectionSelector] === SortDirectionEnum.ASC
                ? SortDirectionEnum.DESC
                : SortDirectionEnum.ASC
        );
    }

    serverAsynTableSortRequest(thElement: HTMLTableCellElement) {
        this.setOrToggleSortDirection(thElement);
        const url = window.location.href;
        const options = this.getAsynPostOptions(thElement);
        fetchRequest({
            requestURL: url,
            resolveHandler: this.asyncTableSortHandler.bind(this),
            payload: options,
        });
    }

    postAsynClientSortState(thElement: HTMLTableCellElement) {
        const url = window.location.href;
        const options = this.getAsynPostOptions(thElement);
        fetchRequest({
            requestURL: url,
            resolveHandler: () => {},
            payload: options,
        });
    }

    asyncTableSortHandler(response: TKResponse) {
        if (!response.success || !response.dataAsHtml) return;

        const html = render(response.dataAsHtml);
        this.replaceAllResponseTable(html);
    }

    replaceAllResponseTable(element: HTMLElement) {
        const oldTables = document.querySelectorAll(this.tableSelector);
        const newTables = element.querySelectorAll(this.tableSelector);

        oldTables.forEach((oldTable) => {
            const correspondingNewTable = Array.from(newTables).find((newTable) => oldTable.id === newTable.id);
            if (correspondingNewTable) {
                oldTable?.parentNode?.replaceChild(correspondingNewTable, oldTable);
            }
        });
    }

    getAsynPostOptions(thElement: HTMLTableCellElement) {
        const table = thElement.closest(this.tableSelector);
        if (!table) return {};
        const client = table.getAttribute(this.dataMasterSelector);
        const sortAttribut = thElement.dataset[this.tkSortAttrSelector];
        const sortDirection = thElement.dataset[this.tkSortDirectionSelector];

        const options: Record<string, string> = {
            type: 'tablesort',
            clientSort: String(!!client),
            tableid: table.id,
            sorting: `{"${sortAttribut}":"${sortDirection}"}`,
        };

        return options;
    }

    removeUnseletectedSortDir(thElement: HTMLTableCellElement) {
        const sortAttribut = thElement.dataset[this.tkSortAttrSelector];
        const table = thElement.closest(this.tableSelector);
        if (!table) return;
        const headers = table.querySelectorAll<HTMLElement>(this.sortDirectionSelector);
        headers.forEach((header) => {
            const headerSortAttr = header.dataset[this.tkSortAttrSelector];
            if (headerSortAttr !== sortAttribut) return;
            header.dataset[this.tkSortDirectionSelector] = '';
        });
    }

    setColAlignStart() {
        const tableBody = this.table?.querySelector('[data-tk-table-body]');
        if (!tableBody) return;
        const cols = tableBody.querySelectorAll('[data-tk-table-row] td');
        if (!cols) return;

        cols.forEach((col) => {
            col.classList.add(this.colAlignStartClassName);
        });
    }

    setColItemAlign(className: string) {
        const tableBody = this.table?.querySelector('[data-tk-table-body]');
        if (!tableBody) return;
        const cols = tableBody.querySelectorAll('[data-tk-table-row] td');
        if (!cols) return;

        cols.forEach((col) => {
            col.classList.add(className);
        });
    }
}