﻿/* eslint-disable no-underscore-dangle */
import TKCustomElementFactory from '@tk/utilities/tk.custom.element.factory';
import isValidJSON from '@/utilities/kw.valid.json';
import parseBooleanOrString from '@/utilities/kw.parse.boolean.string';
import toSnakeCase from '@/utilities/kw.snake.case';
import KWTracker from './kw.tracker';

interface Attribute {
    key: string;
    value: string | boolean;
}

export default class KWTracking extends TKCustomElementFactory {
    trackerElement?: KWTracker;
    event?: string;
    location: string;

    attributeList: Attribute[] = [];
    tracker = window._mtm || [];

    isAsync: boolean;

    constructor() {
        super();

        this.trackerElement = this.closest('kw-tracker') || undefined;
        this.event = this.getAttribute('data-tk-event') || undefined;
        this.isAsync = this.hasAttribute('data-tk-is-async');
        this.location = window.location.href;
    }

    async connectedCallback() {
        if (!this.trackerElement) throw new Error('Tracker Element is missing!');
        if (!this.event) throw new Error('Event is not defined');

        if (this.isAsync) this.init();
        else {
            this.pushListener({
                event: 'kw-tracker-ready' as keyof HTMLElementEventMap | TKCustomEventMap,
                element: this.trackerElement,
                action: this.init.bind(this),
            });
        }
    }

    init() {
        this.getAllAttributes();
        this.pushTracker();
    }

    getAllAttributes() {
        Object.keys(this.dataset).filter((item) => !item.startsWith('tk')).forEach((key) => {
            const value = this.dataset[key];
            if (!value) return;
            const result = isValidJSON(value);

            const attribute: Attribute = {
                key,
                value: result.isValid ? result.json : parseBooleanOrString(value),
            };

            this.attributeList.push(attribute);
        });
    }

    pushTracker() {
        const item = {
            event: this.event,
            location: this.location,
            ...this.attributeList.reduce<Record<string, string | boolean>>((acc, attr) => {
                const key = toSnakeCase(attr.key);
                acc[key] = attr.value;
                return acc;
            }, {}),
        };
        this.tracker.push(item);
    }
}
