﻿import TKModuleFactory from '@tk/utilities/tk.module.factory';
import TKManager from '@tk/basiscs/tk.manager';
import TKButtonDropdown from '@tk/controls/tk.button.dropdown';
import TKNavigationSidebar from '@tk/components/tk.navigation.sidebar';
import TKHeader from '@tk/components/tk.header';
import TKViewport from '@tk/basiscs/tk.viewport';
import TKSearch from '@tk/controls/tk.search';
import TKBasketButton from '@tk/components/tk.basket.button';
import TKCarousel from '@tk/components/tk.carousel';
import TKContextMenu from '@tk/components/tk.context.menu';
import TKFlag from '@tk/components/tk.flag';
import TKMinibasket from '@tk/components/tk.minibasket';
import TKProductTile from '@tk/components/tk.product.tile';
import TKProductVariants from '@tk/components/tk.product.variants';
import TKTooltip from '@tk/components/tk.tooltip';
import TKDialog from '@tk/components/tk.dialog';
import TKStaticContent from '@tk/controls/tk.static.content';
import TKTabsTab from '@tk/controls/tk.tabs.tab';
import TKFormElement from '@tk/controls/tk.form.element';
import TKFormZipCity from '@tk/controls/tk.form.zipcity';
import TKTabsAccordion from '@tk/controls/tk.tabs.accordion';
import TKCompareListLink from '@tk/components/tk.compare.list.link';
import TKCompareButton from '@tk/components/tk.compare.button';
import TKSelection from '@tk/controls/tk.selection';
import TKFormTimepicker from '@tk/controls/tk.form.timepicker';
import TKFormDatepicker from '@tk/controls/tk.form.datepicker';
import TKFormValidator from '@tk/controls/tk.form.validator';
import TKTable from '@tk/components/tk.table';
import KWArticleList from '@/components/kw.article.list';
import KWStock from '@/components/kw.stock';
import KWPrice from '@/components/kw.price';
import KWTracker from '@/components/kw.tracker';
import KWFavoriteInfo from '@/components/kw.favorite.info';
import KWScan from '@/components/kw.scan';
import KWFileUploadArea from '@/components/kw.file.upload.area';
import KWFormElement from '@/components/kw.form.element';
import KWAsync from '@/components/kw.async';
import KWSectionToggler from '@/components/kw.section.toggler';
import KWDialog from '@/components/kw.dialog';
import KWTourSchedule from '@/components/kw.tour.schedule';
import KWTracking from '@/components/kw.tracking';
import KWBasketButton from '@/components/kw.basket.button';
import KWFavoriteButton from '@/components/kw.favorite.button';

import 'flatpickr/dist/flatpickr.min.css';
import 'tippy.js/dist/tippy.css';
import '@splidejs/splide/dist/css/splide.min.css';
import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import '@scss/modules/cv-ces.scss';
import '@scss/modules/general.scss';

export default class General extends TKModuleFactory {
    constructor() {
        super([
            { name: 'tk-manager', constructor: TKManager },
            { name: 'kw-tracker', constructor: KWTracker },
            { name: 'tk-viewport', constructor: TKViewport },
            { name: 'tk-button-dropdown', constructor: TKButtonDropdown },
            { name: 'tk-header', constructor: TKHeader },
            { name: 'tk-nav-sidebar', constructor: TKNavigationSidebar },
            { name: 'tk-search', constructor: TKSearch },
            { name: 'tk-basket-button', constructor: TKBasketButton },
            { name: 'tk-carousel', constructor: TKCarousel },
            { name: 'tk-context-menu', constructor: TKContextMenu },
            { name: 'tk-flag', constructor: TKFlag },
            { name: 'tk-minibasket', constructor: TKMinibasket },
            { name: 'tk-product-tile', constructor: TKProductTile },
            { name: 'tk-product-variants', constructor: TKProductVariants },
            { name: 'tk-tooltip', constructor: TKTooltip },
            { name: 'tk-dialog', constructor: TKDialog },
            { name: 'tk-article-list', constructor: KWArticleList },
            { name: 'tk-price', constructor: KWPrice },
            { name: 'tk-stock', constructor: KWStock },
            { name: 'tk-static-content', constructor: TKStaticContent },
            { name: 'tk-tabs-tab', constructor: TKTabsTab },
            { name: 'tk-favorite-button', constructor: KWFavoriteButton },
            { name: 'tk-favorite-info', constructor: KWFavoriteInfo },
            { name: 'tk-form-element', constructor: TKFormElement },
            { name: 'tk-form-zip-city', constructor: TKFormZipCity },
            { name: 'tk-tabs-accordion', constructor: TKTabsAccordion },
            { name: 'tk-compare-list-link', constructor: TKCompareListLink },
            { name: 'tk-compare-button', constructor: TKCompareButton },
            { name: 'tk-selection', constructor: TKSelection },
            { name: 'kw-form-element', constructor: KWFormElement },
            { name: 'kw-scan', constructor: KWScan },
            { name: 'tk-file-upload-area', constructor: KWFileUploadArea },
            { name: 'kw-async', constructor: KWAsync },
            { name: 'kw-section-toggler', constructor: KWSectionToggler },
            { name: 'tk-form-timepicker', constructor: TKFormTimepicker },
            { name: 'tk-form-datepicker', constructor: TKFormDatepicker },
            { name: 'kw-dialog', constructor: KWDialog },
            { name: 'tk-form-validator', constructor: TKFormValidator },
            { name: 'tk-table', constructor: TKTable },
            { name: 'kw-tour-schedule', constructor: KWTourSchedule },
            { name: 'kw-tracking', constructor: KWTracking },
            { name: 'kw-basket-button', constructor: KWBasketButton },
        ]);
    }
}

// eslint-disable-next-line no-new
new General();
