﻿import { fetchRequest } from '@tk/utilities/tk.fetch';
import TKCustomElementFactory from '@tk/utilities/tk.custom.element.factory';
import render from '@tk/utilities/tk.render';

/**
 * data-tk-dialog-jump-forward
 * data-tk-dialog-jump-backward
 * data-tk-dialog-jump-to
 * data-tk-dialog-close
 * data-tk-dialog-close-and-reset
 * data-tk-close-on-backdrop-click
 */

enum DialogActions {
    JUMP_FORWARD = 'jump_forward',
    JUMP_BACKWARD = 'jump_backward',
    JUMP_TO = 'jump_to',
    RESET_FORM = 'reset_form',
}

interface DialogItem {
    url: string;
    dialog: HTMLDialogElement;
}

interface RenderedDialog {
    className?: string;
    closeClassName?: string;
    innerClassName?: string;
    content?: string;
    isConfirm?: boolean;
    confirmURL?: string;
    confirmTitle?: string;
    confirmDescription?: string;
    confirmCancelIcon?: string;
    confirmCancelLabel?: string;
    confirmSubmitIcon?: string;
    confirmSubmitLabel?: string;
}

export default class TKDialog extends TKCustomElementFactory {
    protected dialogs: DialogItem[] = [];
    protected currentDialog: number = 0;

    url?: string;
    button?: HTMLButtonElement;
    shouldOpen: boolean = false;
    closeOnBackdropClick: boolean = true;
    dialogClassName: string;
    dialogInnerClassName: string;
    dialogCloseClassName: string;
    shouldMaxWidth: boolean;
    maxWidthClassName: string;
    formDataString?: string;
    isPopup: boolean;
    isConfirm: boolean;
    confirmTitle?: string;
    confirmDescription?: string;
    confirmCancelIcon?: string;
    confirmCancelLabel?: string;
    confirmSubmitIcon?: string;
    confirmSubmitLabel?: string;
    forceReloadContent:boolean;

    constructor() {
        super();

        this.url = this.getAttribute('data-tk-url') || undefined;
        this.button = this.querySelector('button') || undefined;
        this.shouldOpen = this.hasAttribute('data-tk-open');
        this.closeOnBackdropClick = this.hasAttribute('data-tk-close-on-backdrop-click')
            ? JSON.parse(this.getAttribute('data-tk-close-on-backdrop-click')!) : true;
        this.shouldMaxWidth = this.hasAttribute('data-tk-should-max-width');
        this.dialogClassName = this.getAttribute('data-tk-dialog-class-name') || 'tk-dialog';
        this.dialogInnerClassName = this.getAttribute('data-tk-dialog-inner-class-name') || 'tk-dialog__inner';
        this.dialogCloseClassName = this.getAttribute('data-tk-dialog-close-class-name')
            || 'tk-button tk-button--tertiary tk-button--only-icon tk-dialog__close';
        this.maxWidthClassName = this.getAttribute('data-tk-max-width-class-name') || 'tk-dialog--max-width';
        this.formDataString = this.getAttribute('data-tk-form-data') || undefined;
        this.isPopup = this.hasAttribute('data-tk-is-popup');
        this.isConfirm = this.hasAttribute('data-tk-is-confirm');
        this.confirmTitle = this.getAttribute('data-tk-confirm-title') || undefined;
        this.confirmDescription = this.getAttribute('data-tk-confirm-description') || undefined;
        this.confirmCancelIcon = this.getAttribute('data-tk-confirm-cancel-icon') || 'cross';
        this.confirmCancelLabel = this.getAttribute('data-tk-confirm-cancel-label') || undefined;
        this.confirmSubmitIcon = this.getAttribute('data-tk-confirm-submit-icon') || 'check';
        this.confirmSubmitLabel = this.getAttribute('data-tk-confirm-submit-label') || undefined;
        this.forceReloadContent = this.hasAttribute('data-tk-force-reload-content');
    }

    connectedCallback(): void {
        if (!this.url) throw new Error('Dialog: URL is missing!');
        this.registerClickListener();
        this.checkAutoOpen();
    }

    registerClickListener(): void {
        if (!this.button) return;
        const onClick = this.openDialog.bind(this, this.url!);
        this.pushListener({ event: 'click', element: this.button, action: onClick });
    }

    registerFormListener(form: HTMLFormElement): void {
        const onSubmit = this.handleForm.bind(this, form);
        this.pushListener({ event: 'submit', element: form, action: onSubmit });
    }

    registerCloseListener(): void {
        const onClick = this.closeDialog.bind(this);
        this.pushListener({ event: 'click', element: window, action: onClick });
    }

    createDialog(url: string): HTMLDialogElement {
        const dialog = TKDialog.renderDialog({
            className: `${this.dialogClassName} ${this.shouldMaxWidth ? this.maxWidthClassName : ''}`,
            closeClassName: this.dialogCloseClassName,
            innerClassName: this.dialogInnerClassName,
            tag: 'dialog',
            isConfirm: this.isConfirm,
            confirmURL: url,
            confirmTitle: this.confirmTitle,
            confirmDescription: this.confirmDescription,
            confirmCancelIcon: this.confirmCancelIcon,
            confirmCancelLabel: this.confirmCancelLabel,
            confirmSubmitIcon: this.confirmSubmitIcon,
            confirmSubmitLabel: this.confirmSubmitLabel,
        });
        const onClosed = this.closedDialog.bind(this);
        this.pushListener({ event: 'close', element: dialog, action: onClosed });
        this.currentDialog = this.dialogs.push({ url, dialog }) - 1;
        return dialog;
    }

    getContent(url: string): void {
        if (this.isConfirm) {
            this.closeCurrentDialog();
            const dialog = this.createDialog(url);
            this.isPopup ? dialog.show() : dialog.showModal();
            this.hookAfterShowModal(dialog);
        } else {
            fetchRequest({
                requestURL: url,
                resolveHandler: this.handleResponseSuccess.bind(this, url),
            });
        }
    }

    getContentWithData(url: string, payload: Record<string, string>): void {
        fetchRequest({
            requestURL: url,
            resolveHandler: this.handleResponseSuccess.bind(this, url),
            payload,
        });
    }

    handleResponseSuccess(url: string, response: TKResponse): void {
        if (!response || !response.success) return;
        this.closeCurrentDialog();
        const dialog = this.createDialog(url);
        const content = dialog.querySelector('[data-tk-dialog-content]');
        if (!content) return;
        content.innerHTML = response.dataAsHtml;
        this.isPopup ? dialog.show() : dialog.showModal();
        this.hookAfterShowModal(dialog);
        const form = content.querySelector('form');
        form && this.registerFormListener(form);
    }

    openDialog(url: string) {
        this.handleUrlAndDialogs(url);
        // This method would be used to execute code in a non-blocking way to prevent potential delays.
        setTimeout(() => {
            this.registerCloseListener();
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    closeDialog(event: any): void {
        const dialogItem = this.dialogs.at(this.currentDialog);
        // Used to check if the event isn't triggered by the enter key
        const isRealClickEvent = event.pointerId !== -1;

        if (!dialogItem || !isRealClickEvent) return;
        const { dialog } = dialogItem;
        if (this.closeOnBackdropClick) {
            TKDialog.closeDialogIfOutside(dialog, event);
        }
    }

    hookAfterShowModal(dialog: HTMLDialogElement): void {
        TKDialog.attachButtonClickHandler(
            dialog,
            '[data-tk-dialog-close]',
            () => dialog.close(),
        );
        TKDialog.attachButtonClickHandler(
            dialog,
            '[data-tk-dialog-close-and-reset]',
            (button) => this.handleActions(DialogActions.RESET_FORM, button),
        );
        TKDialog.attachButtonClickHandler(
            dialog,
            '[data-tk-dialog-jump-forward]',
            (button) => this.handleActions(DialogActions.JUMP_FORWARD, button),
        );
        TKDialog.attachButtonClickHandler(
            dialog,
            '[data-tk-dialog-jump-backward]',
            (button) => this.handleActions(DialogActions.JUMP_BACKWARD, button),
        );
        TKDialog.attachButtonClickHandler(
            dialog,
            '[data-tk-dialog-jump-to]',
            (button) => this.handleActions(DialogActions.JUMP_TO, button),
        );
    }

    static attachButtonClickHandler<T extends HTMLElement = HTMLDialogElement>(
        dialog: T,
        selector: string,
        callback: (button: HTMLButtonElement) => void,
    ): void {
        const buttons = dialog.querySelectorAll<HTMLButtonElement>(selector);
        buttons.forEach((button) => {
            button.addEventListener('click', () => callback(button));
        });
    }

    handleActions(action: DialogActions, button: HTMLButtonElement) {
        const handler: Record<DialogActions, () => void> = {
            jump_forward: () => this.forward(button),
            jump_backward: () => this.backward(),
            jump_to: () => this.jumpTo(button),
            reset_form: () => this.resetForm(button),
        };
        handler[action]();
    }

    closeCurrentDialog() {
        const dialogItem = this.dialogs.at(this.currentDialog);
        if (!dialogItem) return;
        dialogItem.dialog.close();
    }

    handleUrlAndDialogs(url: string) {
        const existsURL = this.dialogs.some((dialogItem) => dialogItem.url === url);
        if (existsURL) {
            const nextDialog = this.getDialogByURL(url);
            if (!nextDialog) return;
            const waitForContent = this.forceReloadContent;
            if (this.forceReloadContent) {
                this.renewDialogContent(nextDialog, url);
            }
            if (waitForContent) return;
            nextDialog.showModal();
        } else {
            try {
                const payload = JSON.parse(this.formDataString || '{}');
                Object.keys(payload).length > 0 ? this.getContentWithData(url, payload) : this.getContent(url);
            } catch (error) {
                throw new Error(`Dialog: ${error}`);
            }
        }
    }

    forward(button: HTMLButtonElement) {
        // Check if already exists
        const url = button.getAttribute('data-tk-url');
        if (!url) throw new Error('Dialog: URL is missing!');
        this.closeCurrentDialog();
        this.handleUrlAndDialogs(url);
        this.currentDialog += 1;
    }

    backward() {
        const nextDialogItem = this.dialogs.at(this.currentDialog - 1);
        if (!nextDialogItem) return;
        this.closeCurrentDialog();
        nextDialogItem.dialog.showModal();
        this.currentDialog -= 1;
    }

    jumpTo(button: HTMLButtonElement) {
        const index = Number(button.getAttribute('data-tk-dialog-jump-to'));
        const nextDialogItem = this.dialogs.at(index);
        if (!nextDialogItem) return;
        this.closeCurrentDialog();
        nextDialogItem.dialog.showModal();
        this.currentDialog = index;
    }

    resetForm(button: HTMLButtonElement) {
        button.form?.reset();
        this.closeCurrentDialog();
    }

    handleForm(form: HTMLFormElement, event: SubmitEvent): void {
        const shouldSwitchToNext = form.hasAttribute('data-tk-switch-to-next');
        if (!shouldSwitchToNext) return;
        event.preventDefault();
        const formData = new FormData(form);
        const url = form.action;

        fetchRequest({
            requestURL: url,
            resolveHandler: this.handleResponseSuccess.bind(this, url),
            payload: formData,
        });
    }

    getDialogByURL(url: string): HTMLDialogElement | undefined {
        const dialogItem = this.dialogs.find((item) => item.url === url);
        return dialogItem?.dialog;
    }

    closedDialog(): void {
        const allDialogClosed = this.dialogs.every((item) => !item.dialog.open);
        if (!allDialogClosed) return;
        this.currentDialog = 0;
        this.removeListener(window);
    }

    static renderDialog<T extends HTMLElement = HTMLDialogElement>(
        options: RenderedDialog & { tag: keyof HTMLElementTagNameMap },
    ): T {
        const {
            tag,
            className = 'tk-dialog',
            closeClassName = 'tk-button tk-button--tertiary tk-button--only-icon tk-dialog__close',
            innerClassName = 'tk-dialog__inner',
            content,
            isConfirm = false,
            confirmURL,
            confirmTitle,
            confirmDescription,
            confirmCancelIcon,
            confirmCancelLabel,
            confirmSubmitIcon,
            confirmSubmitLabel,
        } = options;

        const dialog = render<T>(`
            ${isConfirm ? `
                    <${tag} class="${className}">
                        <button
                            type="button"
                            class="${closeClassName}"
                            data-tk-dialog-close
                        ></button>
                        <div class="${innerClassName}" data-tk-dialog-content>
                            <form action="${confirmURL}" method="post">
                                <h3>${confirmTitle}</h3>
                                <p>${confirmDescription}</p>
                                <div class="flex flex--gap-x-2 flex--justify-end spacer-mt-9">
                                    <button type="button" class="tk-button tk-button--secondary" data-tk-dialog-close>
                                        <span class="tk-button__icon">
                                            <i class="tk-icon-${confirmCancelIcon}"></i>
                                        </span>
                                        <span class="tk-button__label">${confirmCancelLabel}</span>
                                    </button>
                                    <button type="submit" class="tk-button tk-button--primary">
                                        <span class="tk-button__icon">
                                            <i class="tk-icon-${confirmSubmitIcon}"></i>
                                        </span>
                                        <span class="tk-button__label">${confirmSubmitLabel}</span>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </${tag}>
                ` : `
                    <${tag} class="${className}">
                        <button
                            type="button"
                            class="${closeClassName}"
                            data-tk-dialog-close
                        ></button>
                        <div class="${innerClassName}" data-tk-dialog-content>
                            ${content}
                        </div>
                    </${tag}>
                `}
        `);
        document.body.insertAdjacentElement('beforeend', dialog);
        TKDialog.attachButtonClickHandler(
            dialog,
            '[data-tk-dialog-close]',
            () => TKDialog.close(dialog),
        );
        return dialog;
    }

    static open<T extends HTMLElement>(element: T, isPopup = false) {
        if (element instanceof HTMLDialogElement) {
            isPopup ? element.show() : element.showModal();
        } else {
            element.classList.add('tk-dialog--open');
        }
    }

    static close<T extends HTMLElement>(element: T) {
        if (element instanceof HTMLDialogElement) {
            element.close();
        } else {
            element.classList.remove('tk-dialog--open');
            element.dispatchEvent(new Event('close'));
        }
    }

    static closeDialogIfOutside(element: HTMLDialogElement, event: MouseEvent) {
        /**
         * Checking the coordinates of the client for the value 0 and the
         * HTMLSelectElement or HTMLOptionElement element ensures that the
         * dialogue does not close in Mozilla. This is a bug in Firefox
         * that has not yet been fixed.
         */
        if (
            event.clientX === 0
            && event.clientY === 0
            && (event.target instanceof HTMLSelectElement || event.target instanceof HTMLOptionElement)
        ) return;
        const dialogDimensions = element.getBoundingClientRect();
        if (
            event.clientX < dialogDimensions.left
            || event.clientX > dialogDimensions.right
            || event.clientY < dialogDimensions.top
            || event.clientY > dialogDimensions.bottom
        ) {
            element.close();
        }
    }

    checkAutoOpen() : void {
        if (this.shouldOpen) {
            this.openDialog(this.url!);
        }
    }

    renewDialogContent(dialog: HTMLDialogElement, url: string) {
        fetchRequest({
            requestURL: url,
            resolveHandler: this.getRenewDialogContent.bind(this, dialog),
        });
    }

    getRenewDialogContent(openDialog: HTMLDialogElement, response: TKResponse) {
        const dialogInner = openDialog.querySelector('[data-tk-dialog-content]');
        dialogInner!.innerHTML = response.dataAsHtml;
        openDialog.showModal();
        this.hookAfterShowModal(openDialog);
    }
}