﻿import TKCustomElementFactory from '@tk/utilities/tk.custom.element.factory';

export default class TKViewport extends TKCustomElementFactory {
    resizeEventName: string;
    resizeObserver: ResizeObserver;
    breakpoints: Map<Breakpoint, number>;
    currentBreakpoint: Breakpoint;

    constructor() {
        super();

        this.resizeEventName = this.getAttribute('data-tk-resize-event-name') || 'tk-viewport-resize';
        this.resizeObserver = new ResizeObserver(this.checkSize.bind(this));

        // * These breakpoints must match the CSS breakpoints, otherwise conflicts may occur.
        this.breakpoints = new Map([
            ['xs', 576],
            ['s', 768],
            ['m', 992],
            ['l', 1200],
            ['xl', 1440],
        ]);
        this.currentBreakpoint = this.getCurrentBreakpoint(this.clientWidth);
    }

    connectedCallback(): void {
        this.resizeObserver.observe(this);
        window.viewport = this;
    }

    disconnectedCallback(): void {
        super.disconnectedCallback();
        this.resizeObserver.unobserve(this);
    }

    checkSize(entries: ResizeObserverEntry[]) {
        entries.forEach(() => {
            const currentWidth = window.innerWidth;
            const currentBreakpoint = this.getCurrentBreakpoint(currentWidth);
            if (this.currentBreakpoint !== currentBreakpoint) {
                const event = new CustomEvent(this.resizeEventName, {
                    bubbles: true,
                    detail: {
                        width: currentWidth,
                        breakpoint: currentBreakpoint,
                    },
                });
                this.currentBreakpoint = currentBreakpoint;
                this.dispatchEvent(event);
            }
        });
    }

    getCurrentBreakpoint(width: number): Breakpoint {
        let currentBreakpoint: Breakpoint = 'xs';
        this.breakpoints.forEach((value, key) => {
            if (width >= value) {
                currentBreakpoint = key;
            }
        });
        return currentBreakpoint;
    }
}