﻿import TKCustomElementFactory from '@tk/utilities/tk.custom.element.factory';

interface FlagItem {
    icon?: string;
    label?: string;
    showIcon: boolean;
    showLabel: boolean;
}

export default class TKFlag extends TKCustomElementFactory {
    selectorFlagItem: string = '[data-tk-flag-item]';
    selectorFlagIcon: string = '[data-tk-flag-icon]';
    selectorFlagLabel: string = '[data-tk-flag-label]';
    flagItems: FlagItem[] = [];
    removedFlagElements: HTMLDivElement[] = [];

    // eslint-disable-next-line no-useless-constructor
    constructor() {
        super();
    }

    connectedCallback(): void {
        this.getAllFlags();

        if (this.flagItems.length < 1) {
            return;
        }

        this.appendSummarizedFlag();
    }

    getAllFlags(): void {
        const allFlagElements: NodeListOf<HTMLDivElement> = this.querySelectorAll(this.selectorFlagItem) || undefined;
        // convert NodeList to Array
        const allFlags: HTMLDivElement[] = Array.from(allFlagElements);
        // remove last Element (not needed for Tooltip-Flag)
        allFlags.pop();

        if (allFlags.length < 1) {
            return;
        }

        allFlags.forEach((item: HTMLDivElement) => {
            const iconElem: HTMLElement | null = item.querySelector(this.selectorFlagIcon);
            const labelElem: HTMLElement | null = item.querySelector(this.selectorFlagLabel);
            const singleFlagItem: FlagItem = {
                icon: iconElem?.outerHTML,
                label:
                    labelElem?.getAttribute(this.selectorFlagLabel.slice(1, -1)) === 'true' ? labelElem.innerHTML : '',
                showIcon: iconElem?.getAttribute(this.selectorFlagIcon.slice(1, -1)) === 'true',
                showLabel: labelElem?.getAttribute(this.selectorFlagLabel.slice(1, -1)) === 'true',
            };
            this.flagItems.push(singleFlagItem);
            this.removedFlagElements.push(item);
            item.remove();
        });
    }

    getFlagDetails(): string {
        const returnVal: string[] = [];
        this.flagItems.forEach((elem) => {
            let tooltipString: string;
            if (elem.showLabel) {
                tooltipString = elem.showIcon ? `${elem.icon} ${elem.label}` : `${elem.label}`;
            } else {
                const removedElement = this.removedFlagElements.shift();
                const removedElementLabel = removedElement?.querySelector('[data-tk-flag-label]')?.innerHTML;
                const tmpTooltipString = removedElementLabel || '';
                tooltipString = elem.showIcon ? `${elem.icon}` : tmpTooltipString;
            }
            returnVal.push(tooltipString);
        });

        return returnVal.join(', ').trim();
    }

    appendSummarizedFlag(): void {
        const tooltipTemplate: HTMLTemplateElement | null = this.querySelector('[data-tk-flag-merged]');

        if (!tooltipTemplate) {
            return;
        }

        const clone: HTMLElement = tooltipTemplate.content.cloneNode(true) as HTMLElement;
        const tooltipElement: HTMLElement | null = clone.querySelector('[data-tk-tooltip-content]');

        if (!tooltipElement) {
            return;
        }

        tooltipElement.innerHTML = `+${this.flagItems.length}`;
        tooltipElement.setAttribute('data-tk-tooltip-content', this.getFlagDetails());

        this.prepend(clone);
    }
}