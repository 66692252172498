﻿export interface Point {
    x: number;
    y: number;
}

export interface Offset {
    left?: number;
    right?: number;
    top?: number;
    bottom?: number;
}

export interface RectangleItem {
    rectangle: DOMRect;
    offset?: Offset;
}

export function isPointOutsideRectangle(point: Point, rectangle: DOMRect, offset?: Offset): boolean {
    return (
        point.x < rectangle.left + (offset?.left || 0)
        || point.x > rectangle.right + (offset?.right || 0)
        || point.y < rectangle.top + (offset?.top || 0)
        || point.y > rectangle.bottom + (offset?.bottom || 0)
    );
}

export function isOutsideShapes(event: MouseEvent, rectangles: RectangleItem[]): boolean {
    const point: Point = { x: event.clientX, y: event.clientY };
    return rectangles.every((item) => isPointOutsideRectangle(point, item.rectangle, item.offset));
}