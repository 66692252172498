﻿import flatpickr from 'flatpickr';
import { Instance } from 'flatpickr/dist/types/instance';
import { Options } from 'flatpickr/dist/types/options';
import render from '@tk/utilities/tk.render';
import TKFormPicker from './tk.form.picker';

type DatePickerMode = 'multiple' | 'range' | 'single';

type DatePickerMonthSelectorType = 'dropdown' | 'static';

type DatePickerRangeItem = {
    from: string;
    to: string;
};

export default class TKFormDatepicker extends TKFormPicker {
    hideWeekNumbers: boolean;
    minDate?: string;
    maxDate?: string;
    mode: DatePickerMode;
    monthSelectorType: DatePickerMonthSelectorType;
    dateFormat: string;
    conjunction: string;
    defaultDate: string[] | null;
    disableDates: string[];
    disableDatesRange?: DatePickerRangeItem[];
    disableWeekend: boolean;

    constructor() {
        super();

        this.hideWeekNumbers = this.hasAttribute('data-tk-hide-week-numbers');
        this.minDate = this.getAttribute('data-tk-min-date')!;
        this.maxDate = this.getAttribute('data-tk-max-date')!;
        this.mode = (this.getAttribute('data-tk-mode') || 'single') as DatePickerMode;
        this.monthSelectorType = (
            this.getAttribute('data-tk-month-selector-type') || 'dropdown'
        ) as DatePickerMonthSelectorType;
        this.dateFormat = this.getAttribute('data-tk-date-format') || 'd.m.Y';
        this.conjunction = this.getAttribute('data-tk-conjunction') || ',';
        this.defaultDate = this.getAttribute('data-tk-default-date')?.split(';') || null;
        this.disableDates = this.getAttribute('data-tk-disable-dates')?.split(';') || [];
        this.disableWeekend = this.hasAttribute('data-tk-disable-weekend');
        if (this.getAttribute('data-tk-disable-dates-range')) {
            this.disableDatesRange = JSON.parse(this.getAttribute('data-tk-disable-dates-range')!);
        }
    }

    connectedCallback() {
        super.connectedCallback();

        const datePickerConfig = {
            weekNumbers: !this.hideWeekNumbers,
            dateFormat: this.dateFormat,
            minDate: this.minDate,
            maxDate: this.maxDate,
            mode: this.mode,
            monthSelectorType: this.monthSelectorType,
            conjunction: this.conjunction,
            defaultDate: this.defaultDate,
            disable: this.disableDateRanges(),
            onReady: (selectedDates, dateStr, instance) => {
                this.createCustomActionButtons(instance);
            },
        } as Options;

        const config = {
            ...this.config,
            ...datePickerConfig,
        };

        this.flatpickr = flatpickr(this, config);
    }

    disableDateRanges() {
        const disableWeekend = (date: Date) => (date.getDay() === 0 || date.getDay() === 6);

        this.disableDates = this.disableDates.map((element) => element.replace('\n', '').trim());

        return [
            ...(this.disableDatesRange ?? []),
            ...(this.disableDates ?? []),
            ...(this.disableWeekend ? [disableWeekend.bind(this)] : []),
        ];
    }

    renderButton(label: string, className: string, action: () => void) {
        const button = render(`
            <button class="${className}">
                ${label}
            </button>
        `);
        this.pushListener({ event: 'click', element: button, action });
        return button;
    }

    createCustomActionButtons(instance: Instance) {
        const { calendarContainer } = instance;

        const wrapper = render('<div class="flatpickr-calendar__actions-area"></div>');
        const resetButton = this.renderButton(
            window.opacc.projectDatepickerTexts.reset,
            'tk-button tk-button--secondary tk-button--size-s',
            this.resetDatePicker.bind(this),
        );
        const todayButton = this.renderButton(
            window.opacc.projectDatepickerTexts.today,
            'tk-button tk-button--secondary tk-button--size-s',
            this.jumpToToday.bind(this),
        );

        wrapper.appendChild(todayButton);
        wrapper.appendChild(resetButton);
        calendarContainer.appendChild(wrapper);
    }

    jumpToToday() {
        const today = new Date();
        this.flatpickr?.setDate(today, true);
        this.flatpickr?.jumpToDate(today);
    }

    resetDatePicker() {
        this.flatpickr?.clear();
    }
}