﻿import TKCustomElementFactory from '@tk/utilities/tk.custom.element.factory';
import Uppy, { UppyFile } from '@uppy/core';
import Dashboard from '@uppy/dashboard';
import XHR from '@uppy/xhr-upload';
import Compressor, { CompressorOptions } from '@uppy/compressor';
import { fetchRequest } from '@tk/utilities/tk.fetch';
import { v4 as uuidv4 } from 'uuid';

// @ts-ignore the type definition cannot be found
import German from '@uppy/locales/lib/de_DE';
// @ts-ignore the type definition cannot be found
import French from '@uppy/locales/lib/fr_FR';
// @ts-ignore the type definition cannot be found
import Italian from '@uppy/locales/lib/it_IT';
// @ts-ignore the type definition cannot be found
import English from '@uppy/locales/lib/en_US';

interface ResponseData {
    path: string;
    name: string;
    fileId: string;
    success: boolean;
}

interface OtherCompressorOptions {
    mimeType: string;
}

type Language = German | French | English | Italian;

type ExpandCompressorOptions = CompressorOptions & OtherCompressorOptions

export default class TKFileUploadArea extends TKCustomElementFactory {
    uppy?: Uppy;
    api?: string;
    moveAPI?: string;
    usage?: string;
    filePath?: string;
    disableCompressor: boolean;
    outputMimeType: string;
    maxNumberOfFiles: number | null;
    maxFileSize: number | null;
    allowedFileTypes: string[];
    locale: number;
    language: Language;
    filePrefix?: string;
    disableUniqueFileNames: boolean;
    isWithinIFrame: boolean = false;

    constructor() {
        super();
        this.api = this.getAttribute('data-tk-api') || undefined;
        this.moveAPI = this.getAttribute('data-tk-move-api') || undefined;
        this.usage = this.getAttribute('data-tk-usage') || undefined;
        this.filePath = this.getAttribute('data-tk-file-path') || undefined;
        this.disableCompressor = this.hasAttribute('data-tk-disable-compressor');
        this.outputMimeType = this.getAttribute('data-tk-output-mime-type') || 'image/webp';
        this.maxNumberOfFiles = Number(this.getAttribute('data-tk-max-number-of-files')) || null;
        this.maxFileSize = Number(this.getAttribute('data-tk-max-file-size')) || null;
        this.allowedFileTypes = (this.getAttribute('data-tk-allowed-file-types') || 'image/*').split(',');
        this.locale = Number(this.getAttribute('data-tk-locale')) || 1;
        this.disableUniqueFileNames = this.hasAttribute('data-tk-disable-unique-file-names');
        const getLanguage: Record<number, Language> = {
            1: German,
            2: French,
            3: English,
            4: Italian,
        };
        this.language = getLanguage[this.locale];
        this.filePrefix = this.getAttribute('data-tk-file-prefix') || undefined;
        this.isWithinIFrame = this.hasAttribute('data-tk-is-within-iframe');
    }

    connectedCallback(): void {
        if (
            !this.api
            || !this.moveAPI
            || !this.usage
            || !this.filePath
        ) throw new Error('FileUploadArea: Attributes are missing!');
        this.init();
    }

    init() {
        const endpoint = `${this.api}${this.usage}`;
        this.uppy = new Uppy();
        this.uppy.setOptions({
            restrictions: {
                maxFileSize: this.maxFileSize,
                maxNumberOfFiles: this.maxNumberOfFiles,
                allowedFileTypes: this.allowedFileTypes,
            },
            locale: this.language,
        });
        this.uppy.use(Dashboard, { target: this, inline: true, width: '100%' });
        this.uppy.use(XHR, {
            endpoint,
            allowedMetaFields: [],
            getResponseData: (responseText: string, response: unknown) => (
                this.getResponseData(responseText, response)
            ),
        });
        !this.disableCompressor && this.uppy.use(Compressor, {
            mimeType: this.outputMimeType,
        } as ExpandCompressorOptions);
        this.uppy.on('file-added', (file) => {
            const modifiedFileName = this.getFileName(file);
            this.uppy?.setFileMeta(file.id, { name: modifiedFileName });
            this.uppy?.setFileState(file.id, { name: modifiedFileName });
        });
    }

    async getResponseData(responseText: string, response: unknown) {
        if (response instanceof XMLHttpRequest && response.status === 200) {
            const response = await this.moveFile();
            const { path, success } = response.dataAsJson;
            if (!success) {
                const error = { message: 'FAILED!' };
                this.uppy?.emit('error', error);
                this.uppy?.emit('upload-cancel', error);
            } else {
                if (this.isWithinIFrame) {
                    window.parent.postMessage({
                        mceAction: 'close',
                        content: path,
                        size: {
                            width: 0,
                            height: 0,
                        },
                    }, '*');
                }
                return {
                    url: path,
                };
            }
        }
    }

    async moveFile() {
        const data = {
            usage: this.usage,
            path: this.filePath,
        };
        return new Promise<TKResponse<ResponseData>>((resolve) => {
            fetchRequest<ResponseData>({
                requestURL: this.moveAPI!,
                resolveHandler: (success) => resolve(success),
                payload: data,
            });
        });
    }

    getFileName(file: UppyFile<Record<string, unknown>, Record<string, unknown>>) {
        if (this.disableUniqueFileNames && this.filePrefix) {
            return `${this.filePrefix}-${file.name}`;
        }
        if (!this.disableUniqueFileNames && this.filePrefix) {
            return `${this.filePrefix}-${uuidv4()}.${file.extension}`;
        }
        if (!this.disableUniqueFileNames && !this.filePrefix) {
            return `${uuidv4()}.${file.extension}`;
        }
        return file.name;
    }
}