﻿import { fetchRequest, setAsyncUrl } from '@tk/utilities/tk.fetch';
import TKCustomElementFactory from '@tk/utilities/tk.custom.element.factory';
import TKPrice from '@tk/components/tk.price';
import TKStock from '@tk/components/tk.stock';

enum ArticleDataType {
    PRICE = 'price',
    STOCK = 'stock',
}

export interface PriceData {
    source: string;
    service: string;
    catItemId: string;
    articleId: string;
    loadingType: string;
    sort: string;
    key: string;
    asyncTemplate: string;
    element?: TKPrice;
}

export interface StockData {
    source: string;
    catItemId: string;
    articleId: string;
    stockType: string;
    stockLocation: string;
    dispo: string;
    dispoDate: string;
    loadingType: string;
    asyncTemplate: string;
    stockPeriodType: string;
    artUnitType: string;
    element?: TKStock;
}

export interface PriceRequest {
    source: string;
    service: string;
    catitemboid: string;
    catitemartinternalno: string;
    loadingtype: string;
    sort: string;
    key: string;
    template: string;
}

export interface StockRequest {
    source: string;
    catitemboid: string;
    catitemartinternalno: string;
    stocktype: string;
    stockloc: string;
    dispo: string;
    dispodate: string;
    loadingtype: string;
    template: string;
}

export type LoadingType = '1' | '2' | '3';

export interface ResponseItem {
    catitemboid: string;
    catitemartinternalno: string;
    sort: string;
    template: string;
    basispriceisset: boolean;
    promopriceisset: boolean;
    servicepriceisset: boolean;
    loadingtype: LoadingType;
    html?: string;
}

export interface ResponseData {
    items?: ResponseItem[];
}

export default class TKArticleList extends TKCustomElementFactory {
    prices: PriceData[] = [];
    stocks: StockData[] = [];
    isCustomPriceURL: boolean = false;
    isCustomStockURL: boolean = false;

    constructor() {
        super();
    }

    addPrice(item: PriceData) {
        this.prices.push(item);
        this.handlePrice();
    }

    addStock(item: StockData) {
        this.stocks.push(item);
        this.handleStock();
    }

    handlePrice() {
        const elements = this.querySelectorAll('tk-price');
        if (elements.length !== this.prices.length) return;
        const url = elements.item(0).getAttribute('data-tk-async-url') || setAsyncUrl(true);
        this.isCustomPriceURL = url !== setAsyncUrl(true);
        fetchRequest({
            requestURL: url,
            resolveHandler: this.refreshPriceData.bind(this),
            payload: this.getPreparedData(ArticleDataType.PRICE),
        });
    }

    handleStock() {
        const elements = this.querySelectorAll('tk-stock');
        if (elements.length !== this.stocks.length) return;
        const url = elements.item(0).getAttribute('data-tk-async-url') || setAsyncUrl(true);
        this.isCustomStockURL = url !== setAsyncUrl(true);
        fetchRequest({
            requestURL: url,
            resolveHandler: this.refreshStockData.bind(this),
            payload: this.getPreparedData(ArticleDataType.STOCK),
        });
    }

    getPreparedData(type: ArticleDataType) {
        return {
            type,
            ...this.getListByType(type),
        };
    }

    getListByType(type: ArticleDataType) {
        const handler = {
            price: () => this.getPriceData(),
            stock: () => this.getStockData(),
        };
        return handler[type]();
    }

    getPriceData() {
        const items = this.isCustomPriceURL
            ? TKPrice.getItemsAsEntrypoint(this.prices)
            : TKPrice.getItemsAsJSONString(this.prices);
        return items;
    }

    getStockData() {
        const items = this.isCustomStockURL
            ? TKStock.getItemsAsEntrypoint(this.stocks)
            : TKStock.getItemsAsJSONString(this.stocks);
        return items;
    }

    refreshPriceData(response: TKResponse<ResponseData>) {
        if (!response || !response.success) return;
        if (response.dataAsJson.items?.length !== this.prices.length) return;
        const list = response.dataAsJson.items;
        this.prices.forEach((item, index) => {
            const responseItem = list[index];
            const { element } = item;
            element && element.refreshPrice(item, responseItem);
        });
    }

    refreshStockData(response: TKResponse<ResponseData>) {
        if (!response || !response.success) return;
        if (response.dataAsJson.items?.length !== this.stocks.length) return;
        const list = response.dataAsJson.items;
        this.stocks.forEach((item, index) => {
            const responseItem = list[index];
            const { element } = item;
            element && element.refreshStock(item, responseItem);
        });
    }
}