﻿import { fetchRequest } from '@tk/utilities/tk.fetch';
import TKCustomElementFactory from '@tk/utilities/tk.custom.element.factory';
import render from '@tk/utilities/tk.render';
import { RectangleItem, isOutsideShapes } from '@tk/utilities/tk.shape.detector';

interface QuantityResponse {
    quantity: number;
}

export default class TKMinibasket extends TKCustomElementFactory {
    headerButton?: HTMLButtonElement;
    overlay?: HTMLElement;
    contentWrapper?: HTMLUListElement;
    countItemsRequestURL?: string;
    getContentRequestURL?: string;
    headerButtonEmptyClassName: string;
    openClassName: string;
    isDataFetched: boolean = false;
    offset: number;
    minDistanceToBorder: number;

    // Custom Events
    openedEventName: TKCustomEventMap;
    openedEvent: CustomEvent;
    closedEventName: TKCustomEventMap;
    closedEvent: CustomEvent;
    updateEventName: TKCustomEventMap;
    updateEvent: CustomEvent;

    static observedAttributes = ['data-count'];

    constructor() {
        super();

        this.headerButton = this.querySelector<HTMLButtonElement>('[data-tk-header-button]') || undefined;
        this.overlay = this.querySelector('[data-tk-minibasket-overlay]') || undefined;
        this.contentWrapper = this.querySelector('[data-tk-minibasket-content]') || undefined;
        this.countItemsRequestURL = this.getAttribute('data-tk-count-items-request-url') || undefined;
        this.getContentRequestURL = this.getAttribute('data-tk-get-content-request-url') || undefined;
        this.headerButtonEmptyClassName = this.getAttribute('data-tk-header-button-empty-class-name')
            || 'tk-button__header--empty';
        this.offset = Number(this.getAttribute('data-tk-minibasket-offset')) || 4;
        this.openClassName = this.getAttribute('data-tk-open-class-name') || 'tk-minibasket--open';
        this.minDistanceToBorder = Number(this.getAttribute('data-tk-min-distance-to-border')) || 5;

        // Custom Events
        this.openedEventName = (
            this.getAttribute('data-tk-event-opened-name') || 'tk-minibasket-opened'
        ) as TKCustomEventMap;
        this.openedEvent = new CustomEvent(this.openedEventName);
        this.closedEventName = (
            this.getAttribute('data-tk-event-closed-name') || 'tk-minibasket-closed'
        ) as TKCustomEventMap;
        this.closedEvent = new CustomEvent(this.closedEventName);
        this.updateEventName = (
            this.getAttribute('data-tk-event-update-name') || 'tk-minibasket-update'
        ) as TKCustomEventMap;
        this.updateEvent = new CustomEvent(this.updateEventName);
    }

    connectedCallback(): void {
        if (!this.headerButton) throw new Error('Minibasket: Header Button is missing!');
        if (
            !this.countItemsRequestURL
            || !this.getContentRequestURL
        ) {
            throw new Error('Minibasket: Request URLs are missing!');
        }
        this.registerInitializeListener();
        this.fetchQuantity();
    }

    attributeChangedCallback(name: string, oldValue: string, newValue: string) {
        if (name === 'data-count') {
            this.setCounter(Number(newValue));
        }
    }

    registerInitializeListener(): void {
        const handleOpen = this.open.bind(this);
        this.pushListener({ event: 'click', element: this.headerButton!, action: handleOpen });

        const handleOpened = this.opened.bind(this);
        this.pushListener({ event: this.openedEventName, element: this, action: handleOpened });

        const handleUpdate = this.update.bind(this);
        this.pushListener({ event: this.updateEventName, element: this, action: handleUpdate });
    }

    registerCloseListener(): void {
        const handleClose = this.close.bind(this);
        this.pushListener({ event: 'click', element: window, action: handleClose });
    }

    registerScrollListener(): void {
        const handleScroll = this.alignOverlayToButton.bind(this);
        this.pushListener({ event: 'scroll', element: window, action: handleScroll });
    }

    open() {
        document.body.insertAdjacentElement('beforeend', this.overlay!);
        this.overlay!.classList.add(this.openClassName);
        this.alignOverlayToButton();
        !this.isDataFetched && this.fetchAll();
        this.dispatchEvent(this.openedEvent);
    }

    opened() {
        // This method would be used to execute code in a non-blocking way to prevent potential delays.
        setTimeout(() => {
            this.registerCloseListener();
            this.registerScrollListener();
        }, 0);
    }

    update() {
        this.fetchQuantity();
        this.fetchAll();
    }

    close(event: MouseEvent) {
        if (!this.overlay) return;
        const headerButtonRect = this.headerButton!.getBoundingClientRect();
        const overlayRect = this.overlay!.getBoundingClientRect();
        const rectangles = [
            { rectangle: headerButtonRect },
            { rectangle: overlayRect },
        ] as RectangleItem[];
        if (isOutsideShapes(event, rectangles)) {
            this.overlay!.classList.remove(this.openClassName);
            this.dispatchEvent(this.closedEvent);
            this.removeListener(window);
        }
    }

    fetchAll() {
        Promise.all([
            TKMinibasket.fetchData(this.getContentRequestURL!),
        ]).then((responses) => {
            this.isDataFetched = true;
            this.placeContent(responses[0]);
        }).catch(() => {
            this.isDataFetched = false;
        });
    }

    fetchQuantity(): void {
        fetchRequest({
            requestURL: this.countItemsRequestURL!,
            resolveHandler: this.getQuantity.bind(this),
        });
    }

    static fetchData<T>(url: string): Promise<TKResponse<T>> {
        return new Promise((resolve) => {
            fetchRequest({
                requestURL: url,
                resolveHandler: (response: TKResponse<T>) => {
                    resolve(response);
                },
            });
        });
    }

    getQuantity(response: TKResponse<QuantityResponse>): void {
        if (!response || !response.success) return;
        const { quantity } = response.dataAsJson;
        this.setCounter(quantity);
    }

    setCounter(quantity: number) {
        if (quantity === 0) {
            this.headerButton?.classList.add(this.headerButtonEmptyClassName);
        } else {
            this.headerButton?.setAttribute('data-count', `${quantity || 0}`);
            this.headerButton?.classList.remove(this.headerButtonEmptyClassName);
        }
    }

    placeContent(response: TKResponse): void {
        if (!response || !response.success) return;
        if (!this.contentWrapper) return;
        const items = render<HTMLElement>(response.dataAsHtml);
        this.contentWrapper.innerHTML = '';
        this.contentWrapper.insertAdjacentHTML('beforeend', items.innerHTML);
    }

    alignOverlayToButton() {
        const { bottom, right } = this.headerButton!.getBoundingClientRect();
        const { width } = this.overlay!.getBoundingClientRect();
        const distanceToBorder = right - width;

        this.overlay!.style.top = `${bottom + this.offset}px`;

        if (distanceToBorder >= this.minDistanceToBorder) {
            this.overlay!.style.left = `${right - width}px`;
        } else {
            this.overlay!.style.left = '50%';
            this.overlay!.style.transform = 'translateX(-50%)';
        }
    }
}