﻿import TKCustomElementFactory from '@tk/utilities/tk.custom.element.factory';

export default class TKFavoriteInfo extends TKCustomElementFactory {
    anchor?: HTMLAnchorElement;
    href: string;
    quantity: number;
    isMulti: boolean;
    emptyClassName: string;
    disabledClassName: string;

    static observedAttributes = ['data-tk-quantity'];

    constructor() {
        super();
        this.anchor = this.querySelector('a') || undefined;
        this.quantity = Number(this.getAttribute('data-tk-quantity'));
        this.isMulti = this.hasAttribute('data-tk-is-multi');
        this.href = this.getAttribute('data-tk-href') || '';
        this.emptyClassName = this.getAttribute('data-tk-empty-class-name') || 'tk-button__header--empty';
        this.disabledClassName = this.getAttribute('data-tk-disabled-class-name') || 'tk-button__header--disabled';
    }

    attributeChangedCallback(name: string, oldValue: string, newValue: string) {
        if (name === 'data-tk-quantity') {
            if (!this.anchor) return;
            this.anchor.setAttribute('data-count', newValue);
            this.anchor.classList.toggle(this.emptyClassName, Number(newValue) === 0);
            this.anchor.classList.toggle(this.disabledClassName, Number(newValue) === 0);
            // eslint-disable-next-line no-script-url
            this.anchor.href = Number(newValue) === 0 ? 'javascript:void(0)' : this.href;
        }
    }
}