﻿import flatpickr from 'flatpickr';
import { Options } from 'flatpickr/dist/types/options';
import TKFormPicker from './tk.form.picker';

export default class TKFormTimepicker extends TKFormPicker {
    minTime?: string;
    maxTime?: string;
    timeFormat: string;
    defaultHour: number;
    defaultMinute: number;

    constructor() {
        super();

        this.minTime = this.getAttribute('data-tk-min-time')!;
        this.maxTime = this.getAttribute('data-tk-max-time')!;
        this.timeFormat = this.getAttribute('data-tk-time-format') || 'H:i';
        this.defaultHour = Number(this.getAttribute('data-tk-default-hour')) || 12;
        this.defaultMinute = Number(this.getAttribute('data-tk-default-minute')) || 0;
    }

    connectedCallback() {
        super.connectedCallback();

        const timePickerConfig = {
            enableTime: true,
            noCalendar: true,
            time_24hr: true,
            dateFormat: this.timeFormat,
            minTime: this.minTime,
            maxTime: this.maxTime,
            defaultHour: this.defaultHour,
            defaultMinute: this.defaultMinute,
        } as Options;

        const config = {
            ...this.config,
            ...timePickerConfig,
        };

        this.flatpickr = flatpickr(this, config);
    }
}