﻿import TKBasketButton from '@tk/components/tk.basket.button';
import KWPrice from '@/components/kw.price';
import KWBasketAction from '@/utilities/kw.basket.action';

export default class KWBasketButton extends TKBasketButton {
    articleWrapper?: HTMLElement;
    basketAction: KWBasketAction;

    constructor() {
        super();

        this.basketAction = new KWBasketAction(this);
        this.articleWrapper = this.closest('[data-article-wrapper]') || undefined;
    }

    connectedCallback(): void {
        super.connectedCallback();
        this.registerInputListener();
    }

    registerInputListener() {
        if (!this.counter) return;
        const onInput = this.syncPriceQuantity.bind(this);
        this.pushListener({
            event: 'input',
            element: this.counter,
            action: onInput,
        });
    }

    increase() {
        if (!this.counter || !this.increaser) return;
        const {
            step, max, value,
        } = this.counter;
        const valueNumber = Number(value);
        if (Number(max) !== 0 && valueNumber >= Number(max)) return;
        this.counter.value = String(valueNumber + (Number(step) || 1));
        this.syncPriceQuantity();
    }

    decrease() {
        if (!this.counter || !this.decreaser) return;
        const {
            step, min, value,
        } = this.counter;
        const valueNumber = Number(value);
        if (valueNumber <= (Number(min) || 1)) return;
        this.counter.value = String(valueNumber - (Number(step) || 1));
        this.syncPriceQuantity();
    }

    change(event: KeyboardEvent) {
        KWBasketAction.quantityChanger(event);
        this.timer && clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.button?.click();
            this.syncPriceQuantity();
        }, 500);
    }

    syncPriceQuantity() {
        this.timer && clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            if (!this.counter) return;
            const priceElement = this.articleWrapper?.querySelector<KWPrice>('tk-price');
            priceElement?.setAttribute('data-quantity', this.counter.value);
        }, 500);
    }
}
