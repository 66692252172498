﻿export type SortDirection = 'ASC' | 'DESC';
export enum SortDirectionEnum {
    ASC = 'ASC',
    DESC = 'DESC',
}

export const compareStrings = (a: string, b: string, direction: SortDirection) => (
    direction.toUpperCase() === SortDirectionEnum.DESC ? b.localeCompare(a) : a.localeCompare(b)
);

export const compareNumbers = (a: string, b: string, direction: SortDirection) => {
    const numberA = Number(a);
    const numberB = Number(b);
    return direction.toUpperCase() === SortDirectionEnum.DESC ? numberB - numberA : numberA - numberB;
};

export const compareDates = (a: string, b: string, direction: SortDirection) => {
    const dateFormattedA = a.split('.').reverse().join('-');
    const dateFormattedB = b.split('.').reverse().join('-');
    const dateA = Date.parse(dateFormattedA);
    const dateB = Date.parse(dateFormattedB);

    return direction.toUpperCase() === SortDirectionEnum.DESC ? dateB - dateA : dateA - dateB;
};

export const compareTimes = (a: string, b: string, direction: SortDirection) => {
    const dateA = new Date();
    const dateB = new Date();
    const splittedA: number[] = a.split(':').map((item) => Number(item));
    const splittedB: number[] = b.split(':').map((item) => Number(item));
    dateA.setHours(splittedA.at(0) || 0, splittedA.at(1), splittedA.at(2));
    dateB.setHours(splittedB.at(0) || 0, splittedB.at(1), splittedB.at(2));
    const timeA = dateA.getTime();
    const timeB = dateB.getTime();

    return direction.toUpperCase() === SortDirectionEnum.DESC ? timeB - timeA : timeA - timeB;
};

export const compareTimestamps = (a: string, b: string, direction: SortDirection) => {
    const timeStampListA = a.split(' ');
    const timeStampListB = b.split(' ');
    const dateA = timeStampListA.at(0)?.split('.').reverse().join('-');
    const dateB = timeStampListB.at(0)?.split('.').reverse().join('-');
    const timeA = timeStampListA.at(1);
    const timeB = timeStampListB.at(1);
    const timestampA = Date.parse(`${dateA}T${timeA}`);
    const timestampB = Date.parse(`${dateB}T${timeB}`);

    return direction.toUpperCase() === SortDirectionEnum.DESC ? timestampB - timestampA : timestampA - timestampB;
};

export const compareBooleans = (a: string, b: string, direction: SortDirection) => {
    const booleanA = Boolean(a);
    const booleanB = Boolean(b);
    const numberA = Number(booleanA);
    const numberB = Number(booleanB);
    return direction === SortDirectionEnum.DESC ? numberB - numberA : numberA - numberB;
};