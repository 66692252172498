﻿import TKStock from '@tk/components/tk.stock';
import { fetchRequest } from '@tk/utilities/tk.fetch';
import { StockData } from '@tk/components/tk.article.list';

const READY_TO_RUN_ATTR = 'data-tk-ready-to-run';

export default class KWStock extends TKStock {
    asyncURLEncrypt: string | null;
    readyToRun?: boolean;
    isCached: boolean;

    static observedAttributes = [READY_TO_RUN_ATTR];

    constructor() {
        super();

        this.asyncURLEncrypt = this.getAttribute('data-tk-async-crypt');
        this.readyToRun = this.getAttribute(READY_TO_RUN_ATTR) === 'true';
        this.isCached = this.hasAttribute('data-tk-is-cached');
    }

    connectedCallback(): void {
        if ((this.hasAttribute(READY_TO_RUN_ATTR) && !this.readyToRun) || this.isCached) return;
        super.connectedCallback();
    }

    attributeChangedCallback(name: string, oldValue: string, newValue: string) {
        if (name === READY_TO_RUN_ATTR && newValue === 'true') {
            !this.isCached && super.connectedCallback();
        }
    }

    handleStock(item: StockData) {
        const items = TKStock.getItemsAsEntrypoint([item]);

        const data = {
            ...items,
        } as Record<string, string>;

        this.asyncURLEncrypt && (data.crypt = this.asyncURLEncrypt);

        fetchRequest({
            requestURL: this.asyncURL,
            payload: data,
            resolveHandler: this.handleResponse.bind(this),
        });
    }

    static getItemsAsArray(data: StockData[]) {
        const allArticles = data.map((item) => item.articleId);
        const articles = [...new Set(allArticles)];
        return articles;
    }
}