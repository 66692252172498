﻿/* eslint-disable class-methods-use-this */
import TKCustomElementFactory from '@tk/utilities/tk.custom.element.factory';

export default class KWTracker extends TKCustomElementFactory {
    static isInitialized = false;

    trackingId?: string;
    url?: string;
    // eslint-disable-next-line no-underscore-dangle
    tracker = window._mtm || [];
    ready: CustomEvent;

    constructor() {
        super();

        this.trackingId = this.getAttribute('data-tk-tracking-id') || undefined;
        this.ready = new CustomEvent('kw-tracker-ready');
    }

    async connectedCallback() {
        if (!KWTracker.isInitialized) {
            KWTracker.isInitialized = true;
        }
        if (!this.trackingId) throw new Error('Tracking id is not defined');
        this.url = `https://matomo01.opacc.net/js/container_${this.trackingId}.js`;
        await this.initMatomoScript();
        this.dispatchEvent(this.ready);
    }

    async initMatomoScript() {
        if (!document.querySelector(`script[src="${this.url}"]`)) {
            await this.loadMatomoScript();
            this.initializeMatomo();
        }
    }

    loadMatomoScript() {
        return new Promise((resolve, reject) => {
            if (!this.url) return;
            const scriptElement = document.createElement('script');
            scriptElement.src = this.url;
            scriptElement.async = true;
            scriptElement.onload = resolve;
            scriptElement.onerror = reject;
            document.head.appendChild(scriptElement);
        });
    }

    initializeMatomo() {
        this.tracker.push({
            'mtm.startTime': (new Date().getTime()),
            event: 'mtm.Start',
        });
    }
}
