﻿import { Splide, Options } from '@splidejs/splide';
import TKCustomElementFactory from '@tk/utilities/tk.custom.element.factory';

export default class TKCarousel extends TKCustomElementFactory {
    root?: HTMLElement;
    origin?: HTMLElement;
    options?: string;
    customOptions: object = {};
    paginationClassName: string;
    paginationPageClassName: string;
    arrowsClassName: string;
    arrowClassName: string;
    prevClassName: string;
    nextClassName: string;
    loadedClassName: string;
    slider?: Splide;

    constructor() {
        super();
        this.root = this.querySelector('[data-tk-carousel-root]') || undefined;
        this.options = this.getAttribute('data-tk-carousel-options') || undefined;
        this.paginationClassName = this.getAttribute('data-tk-pagination-class-name') || 'tk-carousel__pagination';
        this.paginationPageClassName = this.getAttribute('data-tk-pagination-page-class-name')
            || 'tk-carousel__pagination-page';
        this.arrowsClassName = this.getAttribute('data-tk-arrows-class-name') || 'tk-carousel__arrows';
        this.arrowClassName = this.getAttribute('data-tk-arrow-class-name') || 'tk-carousel__arrow';
        this.prevClassName = this.getAttribute('data-tk-prev-class-name') || 'tk-carousel__arrow--prev';
        this.nextClassName = this.getAttribute('data-tk-next-class-name') || 'tk-carousel__arrow--next';
        this.loadedClassName = this.getAttribute('data-tk-loaded-class-name') || 'tk-carousel--loaded';
        if (this.options) {
            this.customOptions = JSON.parse(this.options);
        }
    }

    connectedCallback(): void {
        this.mountCarousel();
    }

    mountCarousel(): void {
        const carouselBreakpoints: Record<number, Options> = {};
        carouselBreakpoints[window.viewport.breakpoints.get('l') || 1200] = {
            perPage: 3,
            arrows: true,
            pagination: true,
        };

        const defaultOptions = {
            type: 'slide',
            arrows: false,
            pagination: false,
            perMove: 1,
            padding: { left: '4px', right: '4px' },
            gap: 24,
            mediaQuery: 'min',
            classes: {
                pagination: `splide__pagination ${this.paginationClassName}`,
                page: `splide__pagination__page ${this.paginationPageClassName}`,
                arrows: `splide__arrows ${this.arrowsClassName}`,
                arrow: `splide__arrow ${this.arrowClassName}`,
                prev: `splide__arrow--prev ${this.prevClassName}`,
                next: `splide__arrow--next ${this.nextClassName}`,
            },
            breakpoints: carouselBreakpoints,
            width: '100%',
        } as Options;
        if (!this.root) return;
        this.slider = new Splide(this.root, Object.assign(defaultOptions, this.customOptions));
        this.slider.on('ready', this.movePaginationToArrows.bind(this));
        this.slider.on('resized', this.movePaginationToArrows.bind(this));
        this.slider.mount();
    }

    movePaginationToArrows() {
        const arrows = this.querySelector(`.${this.arrowsClassName}`);
        const pagination = this.querySelector(`.${this.paginationClassName}`);
        if (arrows?.querySelector(`.${this.paginationClassName}`)) return;
        if (!arrows || !pagination) return;
        arrows?.insertAdjacentElement('beforeend', pagination);
        this.root?.classList.add(this.loadedClassName);
    }
}