﻿import TKCustomElementFactory from '@tk/utilities/tk.custom.element.factory';
import { fetchRequest } from '@tk/utilities/tk.fetch';
import render from '@tk/utilities/tk.render';

const LOADING_ATTR = 'data-tk-loading';

export default class KWAsync extends TKCustomElementFactory {
    protected contentURL?: string;
    isLoaded?: string;
    hasCustomLoader = false;

    static observedAttributes = [LOADING_ATTR];

    constructor() {
        super();

        this.hasCustomLoader = this.hasAttribute('data-tk-has-custom-loader');
        this.contentURL = this.getAttribute('data-tk-content-url') || undefined;
        this.isLoaded = this.getAttribute(LOADING_ATTR) || undefined;
        !this.hasCustomLoader && this.addLoader();
    }

    connectedCallback(): void {
        if ((!this.isLoaded || this.isLoaded === 'true') && !this.hasAttribute('data-tk-no-load')) {
            if (!this.contentURL) throw new Error('KWAsync: Missing content URL');
            this.getContent();
        }
    }

    getContent(): void {
        if (!this.contentURL) return;
        fetchRequest({
            requestURL: this.contentURL,
            resolveHandler: this.renderContent.bind(this),
        });
    }

    attributeChangedCallback(name: string, oldValue: string, newValue:string) {
        if (name === LOADING_ATTR && newValue === 'true') {
            this.getContent();
        }
    }

    renderContent(response: TKResponse) {
        const body = render(response.dataAsHtml, true);
        this.replaceChildren(body); // replaces the progressbar with fetched content

        this.modalHandler();
    }

    addLoader() {
        const loader = render(`
            <div class="tk-progress tk-progress--infinite">
                <div class="tk-progress__bar"></div>
            </div>
        `);
        this.insertAdjacentElement(
            'beforeend',
            loader,
        );
    }

    modalHandler():void {
        const modalCloseBtn = this.querySelector('[data-tk-dialog-close-and-reset]');
        const modal = modalCloseBtn?.closest('dialog') || undefined;

        if (modalCloseBtn && modal) {
            modalCloseBtn.addEventListener('click', () => {
                modal.close();
            });
        }
    }
}